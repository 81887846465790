import React from 'react';
import logo from '../assets/img/newlogo/file (1).jpg'; // Replace this with the path to your logo file

const Footer = () => {
  return (
    <footer className="bg-black py-4 text-white">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
          {/* Contact Information */}
          <div className="text-center md:text-left">
            <h4 className="text-lg font-semibold mb-3">Contact Us</h4>
            <p className="text-sm">
              <span className="font-medium">Phone:</span> +91 80077 83486
            </p>
            <p className="text-sm">
              <span className="font-medium">Email:</span>{' '}
              <a
                href="mailto:info@example.com"
                className="hover:underline text-secondary"
              >
                info@example.com
              </a>
            </p>
            
          </div>

          {/* Logo Section */}
          <div className="flex justify-center items-center">
            <img
              src={logo}
              alt="Navimumbai Properties Logo"
              className="w-24 h-24 object-contain"
            />
          </div>

          {/* Copyright Section */}
          <div className="text-center md:text-right">
            <p className="text-sm mt-4">
              Copyright &copy; 2024 Navimumbai Properties. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
