import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/newlogo/navimumbai_new logo.jpg';

const Header = ({ fixed }) => {
  return (
    <header
      className={`py-4 mb-12 border-b ${
        fixed ? 'fixed top-0 left-0 w-full z-10 bg-white shadow-lg' : ''
      }`}
    >
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="ml-[-10px]"> {/* Adjust this value as needed */}
          <img
            src={Logo}
            alt="Logo"
            className="h-8 object-contain"
            style={{ width: '142px' }}
          />
        </Link>
        <div className="flex items-center gap-6"></div>
      </div>
    </header>
  );
};

export default Header;
